import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
	Tab,
	Table,
	Button,
	Segment,
	Icon,
	Header,
	Message,
} from "semantic-ui-react";
import uuid from "uuid";
import BlurChangeInput from "../controls/blurChangeInput";
import ConfirmButton from "../controls/confirmButton";
import BaseDataSelect from "../../basedata/selects";

const EditDispo = (props) => {
	const { t } = useTranslation();
	const state = props.data.state.formData.disposition;
	const truckTonnage = props.data.state.formData.truckTonnage;
	const truckRequirements = props.data.state.formData.truck_requirements === false ? [] : props.data.state.formData.truck_requirements ?? [];
	const isBPO = !!props.data.state.formData.bpo.length;
	let allSuppliers = Array.from(props.data.props.app.basedata['suppliers']);
	let allowedSuppliers = truckRequirements.map((v) => v.plant);
	if(truckRequirements.length === 0){
		allowedSuppliers = allSuppliers.map((supplier) => supplier[1].name);
	}
	allSuppliers = allSuppliers.map(supplier => {
		if(truckRequirements.length > 0){
			let requirement = truckRequirements.find((value) => {
				return value.plant === supplier[1].name;
			});
			if(requirement !== null && requirement !== undefined){
				supplier.maxTruckAmount = requirement.count;
			}
		}
		return supplier;
	});
	

	if (!state.mergeable) {
		return (
			<Tab.Pane>
				<Segment placeholder>
					<Header icon>
						<Icon name="truck" />
						{t("edit.difference.header", {
							type: t("edit.difference.types.dispo"),
						})}
						<Header.Subheader>
							{t("edit.difference.message", {
								type: t("edit.difference.types.dispo"),
							})}
						</Header.Subheader>
					</Header>
					{!props.edit.state.data.editable ||
					props.data.state.formData.bpo.length ? null : (
						<ConfirmButton
							trigger={
								<Button negative>
									{t("edit.difference.action", {
										type: t("edit.difference.types.dispo"),
									})}
								</Button>
							}
							onClick={() => props.data.resetOrderDispo("disposition")}
						/>
					)}
				</Segment>
			</Tab.Pane>
		);
	}

	let noDisposition = (
		<Fragment>
			<Table.Body>
				<Table.Row>
					<Table.Cell>
						<Segment placeholder>
							<Header icon>
								<Icon name="truck" />
								{t("edit.nodata.dispo.message")}
							</Header>
						</Segment>
					</Table.Cell>
				</Table.Row>
			</Table.Body>
		</Fragment>
	);

	let comment_ref = [], final_comment = '';
	Object.entries(state.data).map(([key, x]) => {
		comment_ref.push(x);
		final_comment = x.comment;
		return x;
	});

	let dispositon = (
		<Fragment>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>
						{t("edit.fields.dispoDeliverer.label")}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t("edit.fields.dispoType.label")}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t("edit.fields.dispoAmount.label")}
						{props.data.state.validationErrors.length > 0 ? (<br />) : null}
						{props.data.state.validationErrors.map((error, index, array) => 
							(<i className="truck-error" key={uuid.v4()}>{error}{((index + 1) !== array.length) ? ", ":""}</i>))
						}
					</Table.HeaderCell>
					{!props.edit.state.data.editable ? null : (
						<Table.HeaderCell />
					)}
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{Object.entries(state.data).map(([key, x]) => (
					<Table.Row key={x.key}>
						<Table.Cell>
							<BaseDataSelect
								value={x.supplier}
								sortUp={props.edit.state.suppliers}
								onChange={(e, { value }) => {
									props.data.changeOrderDispo(
										"disposition",
										"supplier",
										value,
										x.reference
									);
									props.edit.saveHistory();
								}}
								disabled={
									!props.edit.state.data.editable 
								}
								allowedSuppliers={isBPO ? allowedSuppliers : null}
								type="suppliers"
							/>
						</Table.Cell>
						<Table.Cell>
							<BaseDataSelect
								value={x.type}
                                // :TODO: check double negation
								omit={props.data.state.formData.bpo.length ? [] : ["?"]}
								onChange={(e, { value }) => {
									props.data.changeOrderDispo(
										"disposition",
										"type",
										value,
										x.reference
									);
									props.edit.saveHistory();
								}}
								type="truckTypes"
								disabled={
									!props.edit.state.data.editable 
								}
							/>
						</Table.Cell>
						<Table.Cell>
							<BlurChangeInput
								placeholder={t("edit.fields.dispoAmount.placeholder")}
								value={x.amount}
								type="number"
								fluid
								onBlur={(e) => {
									props.data.changeOrderDispo(
										"disposition",
										"amount",
										isNaN(parseInt(x.amount)) || x.amount < 0
											? 0
											: parseInt(x.amount),
										x.reference
									);
									props.edit.saveHistory();
									props.data.validateTrucksCountForSuppliers(props.data.state.formData.disposition.data, allSuppliers, props.data.state.marked);
								}}
								onChange={(e, { value }) => {
										props.data.changeOrderDispo(
											"disposition",
											"amount",
											value,
											x.reference,
											true
										);
										setTimeout(() => {
											props.data.validateTrucksCountForSuppliers(props.data.state.formData.disposition.data, allSuppliers, props.data.state.marked);
										}, 150);
										
									}
								}
								disabled={
									!props.edit.state.data.editable
								}
							/>
						</Table.Cell>
						{!props.edit.state.data.editable ? null : (
							<Table.Cell>
								<ConfirmButton
									ok="yes"
									cancel="no"
									trigger={
										<Button
											floated="right"
											icon="trash alternate"
											size="small"
										/>
									}
									onClick={(e) => {
										props.data.changeOrderDispo(
											"disposition",
											"deleted",
											true,
											x.reference
										);
										props.edit.saveHistory();
									}}
								/>
							</Table.Cell>
						)}
					</Table.Row>
				))}
			</Table.Body>
		</Fragment>
	);

	return (
		<Tab.Pane>
			{truckTonnage ? (
				<Message
					size="small">
					<p>
						{t("edit.truckTonnage") + ": " + truckTonnage + "t"}
					</p>
					<p>
						{((isBPO && truckRequirements !== null) ? (
							`\n${t("truckRequirements")}: ` + truckRequirements.reduce((result, value) => {
								return result + (result.length > 0 ? ", " : "") + `${value.plant} - ${value.count}`;
							}, "")
						):"")}
					</p>
				</Message>
			) : null}
			<Table singleLine>
				{Object.entries(state.data).length === 0 &&
				state.data.constructor === Object
					? noDisposition
					: dispositon}
				{!props.edit.state.data.editable ? null : (
					<Table.Footer fullWidth>
						<Table.Row>
							<Table.HeaderCell colSpan="4">
								<Button
									floated="right"
									icon
									labelPosition="left"
									primary
									size="small"
									onClick={(_) => props.data.addOrderDispo("disposition")}
								>
									<Icon name="user" /> {t("edit.buttons.newDispo")}
								</Button>
							</Table.HeaderCell>
						</Table.Row>
						{Object.entries(state.data).length === 0 &&
							state.data.constructor === Object ? null : (
							<Table.Row>
								<Table.HeaderCell colSpan="4">
									<div className="ui form">
										<BlurChangeInput
											textarea={true}
											label={t("edit.fields.comments.label")}
											placeholder={t("edit.fields.comments.placeholder")}
											value={final_comment ?? ''}
											onBlur={(_) => props.edit.saveHistory()}
											onChange={(e, { value }) =>
												comment_ref.map((x) =>
													props.data.changeOrderDispo(
														"disposition",
														"comment",
														value,
														x.reference
													)
												)
											}
											disabled={!props.edit.state.data.editable}
										/>
									</div>
								</Table.HeaderCell>
							</Table.Row>
						)}
					</Table.Footer>
				)}
			</Table>
		</Tab.Pane>
	);
};

export default EditDispo;
