import React from "react";
import uuid from "uuid";
// import { Button } from "semantic-ui-react";
// import ConfirmButton from "../controls/confirmButton";
// import moment from "moment";

const TrucksCountErrors = (props) => {
	return (
		<div className="collisionHandler">
			<div className="resourceName">
				{props.process.name}
			</div>
			{props.errors.map( (errorDateObject, index) => (
				<div className="project" key={uuid.v4()}>
					<div className="time">
						{errorDateObject.date}
					</div>
					<div className="name">
						{errorDateObject.errors.join(", ")}
					</div>
				</div>) )
			}
			
		</div>
	);
};

export default TrucksCountErrors;
