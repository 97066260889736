import React from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import DraggableCore from "react-draggable";
import { isValidReference } from "mobx-state-tree";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import ConfirmButton from "../controls/confirmButton";
import ProjectResourceLines from "./projectResourceLines";
import AGFModal from "../../addons/AGFModal";
import ProjectRequestLines from "./projectRequestLines";

class ProjectLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dayMap: new Map(),
			pid: "",
			sub: "",
			currentView: props.app.ui.view,
			x: 0,
			processesVisible: false,
            calendarState: props.calendar.state
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (
			state.pid !== "" &&
			props.app.ui.view.start.getTime() !== state.currentView.start.getTime()
		) {
			let add = ProjectLine.getWidth(
				props,
				props.app.ui.view.start.getTime() - state.currentView.start.getTime()
			);

			let newx = add + state.x;

			//add add to daymap
			let newMap = ProjectLine.moveHandler(props, state, newx);

			return {
				currentView: props.app.ui.view,
				dayMap: newMap,
				x: newx,
			};
		}
		return null;
	}

	toggleProcessVisible() {
		this.setState((state) => ({
			processesVisible: !state.processesVisible,
		}));
	}

	onStart(sub, pid, start) {
		let alsoMoving = this.props.project.days;
		//this.props.project.setMoving(this.props.position);
		this.setState({
			dayMap: alsoMoving,
			currentView: this.props.app.ui.view,
			pid: pid,
			sub: sub,
			x: 0,
		});
	}

	static moveHandler(props, state, x) {
		let start = props.project.unifiedByDay.get("main").data.get(state.pid);
		const pid = state.pid;
		//console.log(props.project.unifiedByDayWithProcesses, state.sub);
		let daylist = Array.from(
			props.project.unifiedByDayWithProcesses.get(state.sub).data
		).filter(function([key, d]) {
			return (x < 0 && key < pid) || (x > 0 && key > pid);
		});
		daylist.sort(function(a, b) {
			return a[0] < b[0]
				? x > 0
					? -1
					: 1
				: a[0] === b[0]
				? 0
				: x > 0
				? 1
				: -1;
		});

		let xmap = new Map();

		xmap.set(state.pid, x);

		let last = ProjectLine.getX(props, start[x < 0 ? "start" : "end"]) + x;

		let used_replacement_days = new Map();

		for (let i = 0; i < daylist.length; i++) {
			let [key, date] = daylist[i];
			let end = ProjectLine.getX(props, date[x < 0 ? "end" : "start"]);

			if ((x < 0 && end <= last) || (x > 0 && end >= last)) break;

			let localx = last - end;

			let target_day = moment(
				Math.round((date.start.getTime() + date.end.getTime()) / 2) +
					ProjectLine.getDate(props, localx)
			).startOf("day");
			let target_day_x = moment(
				date.start.getTime() + ProjectLine.getDate(props, localx)
			).valueOf();
			let weekender = false;

			while (
				!state.dayMap.has(target_day.format("YYYY-MM-DD")) &&
				(!props.app.basedata.checkWorkingDay(target_day).state ||
					used_replacement_days.has(target_day.format("YYYY-MM-DD")))
			) {
				weekender = true;
				target_day.add(x > 0 ? 1 : -1, "day");
			}

			let target_day_y = target_day.valueOf();

			if (weekender) {
				used_replacement_days.set(target_day.format("YYYY-MM-DD"), true);
				localx += ProjectLine.getWidth(props, target_day_y - target_day_x);
			}

			xmap.set(key, localx);

			last = ProjectLine.getX(props, date[x < 0 ? "start" : "end"]) + localx;
		}

		let newMap = new Map();
		for (let [key] of state.dayMap) {
			newMap.set(key, xmap.has(key) ? xmap.get(key) : 0);
		}
		return newMap;
	}

	onStop(start, end, x) {
		let newMap = ProjectLine.moveHandler(this.props, this.state, x);
		let processIdToMove = this.state.sub;
		this.setState({
			dayMap: new Map(),
			pid: "",
			sub: "",
			x: 0,
		});

		const moveList = [];
		
		for (let [processId, process] of this.props.project.$processes) {
			if (
				!isValidReference(() => process) ||
				(processId !== processIdToMove && processIdToMove !== "main")
			)
				continue;
			for (let job of process.$jobs.values()) {
				if (!isValidReference(() => job)) continue;
				let momday = moment(job.start);
				let key = momday.format("YYYY-MM-DD");

				if (!newMap.has(key)) continue;

				let offset = newMap.get(key);

				if (offset === 0) continue;

				let diff = ProjectLine.getDate(this.props, offset);

				let olddaystart = moment(momday)
					.startOf("day")
					.valueOf();
				let olddayend = moment(momday)
					.endOf("day")
					.valueOf();

				let newdaystart = olddaystart + diff;
				let newdayend = olddayend + diff;

				let realnewdaystart = moment(
					Math.round((newdaystart + newdayend) / 2)
				).startOf("day");

				let newstart = moment(momday).add(
					realnewdaystart.diff(olddaystart, "day"),
					"day"
				);
				let movediff = newstart.valueOf() - momday.valueOf();

				if (!movediff) continue;

				moveList.push([job, movediff]);
			}
		}
		/* eslint-enable no-unused-vars */
		if (moveList.length) {
			this.props.calendar.resetSelected();
			this.props.app.projects.move(moveList);
		}

		this.props.project.setMoving(-1);
	}

	onDrag(x) {
		let newMap = ProjectLine.moveHandler(this.props, this.state, x);

		this.setState({
			x: x,
			dayMap: newMap,
		});
	}

	static getX(props, date) {
		return (
			((date.getTime() - props.app.ui.view.start.getTime()) /
				props.app.ui.calDuration) *
			props.xwidth
		);
	}

	static getDate(props, x) {
		return (props.app.ui.calDuration * x) / props.xwidth;
	}

	static getWidth(props, x) {
		return (x * props.xwidth) / props.app.ui.calDuration;
	}

	deleteResources(project){
		project.resources.forEach((res) => {
			// eslint-disable-next-line
			for (let [personalId, dateData] of Object.entries(res.days)) {  
				dateData.forEach(job => { 
					job.dispose(res.resource, "REMOVE")
				});
			}
		});		 
	}

	render() {
		const lines = [];
		const resMode = this.props.app.ui.mode === "RESOURCES";
		const width =
			((this.props.app.ui.view.end - this.props.app.ui.view.start) /
				this.props.app.ui.calDuration) *
			this.props.xwidth;

		const project = this.props.project;

		const unifiedProjectData = this.state.processesVisible
			? project.unifiedByDayWithProcesses
			: project.unifiedByDay;

		for (let [subId, subprocess] of unifiedProjectData) {
			const boxes = [];

			const lineHeight = subId === "main" ? 30 : 20;
			const padding = 0;
			const boxHeight = lineHeight - 2 * padding;

			for (let [personalId, dateData] of subprocess.data) {
				let start = ProjectLine.getX(this.props, dateData.start);
				let end = ProjectLine.getX(this.props, dateData.end);

				let isBeingMoved = this.state.dayMap.has(personalId);

				let hovered =
					this.props.app.ui.mouseOver.day === personalId &&
					((!this.state.processesVisible &&
						subId === "main" &&
						this.props.app.ui.mouseOver.projects.includes(project.id)) ||
						(subId !== "main" &&
							this.props.app.ui.mouseOver.processes.includes(subId)));

				let pos = 0;
				let offset = 0;

				let moveMain =
					false && //I disabled it because it looks weird when you move processes
					this.state.pid !== "" &&
					this.state.sub !== "main" &&
					subId === "main" &&
					typeof dateData.processes !== "undefined" &&
					dateData.processes.size === 1 &&
					dateData.processes.has(this.state.sub);

				if (this.state.pid === "") {
				} else if (
					isBeingMoved &&
					personalId !== this.state.pid &&
					(subId === this.state.sub || this.state.sub === "main" || moveMain)
				) {
					offset = this.state.dayMap.get(personalId);
				} else if (
					isBeingMoved &&
					personalId === this.state.pid &&
					(subId === this.state.sub || this.state.sub === "main" || moveMain)
				) {
					pos = this.state.dayMap.get(personalId);
				}

				const marked = this.state.calendarState.selected.includes(
					project.id + "#" + (subId === "main" ? "*" : subId) + "#" + personalId
				);

				const starter = function(startx, subid, pid) {
					return function(x, y) {
						return this.onStart(subid, pid, startx);
					}.bind(this);
				}.bind(this)(dateData.start, subId, personalId);

				const ender = function(startx, endx) {
					return function(x, y) {
						return this.onStop(startx, endx, y.x);
					}.bind(this);
				}.bind(this)(dateData.start, dateData.end);
				boxes.push(
					<DraggableCore
						axis="none"
						onDrag={(x, y) => this.onDrag(y.x)}
						onStart={starter}
						key={subId + "-" + personalId}
						onStop={ender}
						//defaultPosition={{ x: pos, y: 0 }}
						position={{ x: pos, y: 0 }}
						disabled={!project.moveable || project.fixed}
					>
						<div
							className={
								(subId === "main" ? "standard_box" : "process_box") +
								(offset !== 0 || pos !== 0 ? " moved" : "") +
								(marked ? " marked" : "") +
								(hovered ? " hovered" : "") +
								(dateData.isNight ? " night" : "")
							}
							style={{
								cursor: "pointer",
								position: "absolute",
								left: start + 1 + offset,
								width: end - start - 1,
								height: boxHeight - 1,
								top: padding,
							}}
							onClick={
								(this.props.app.ui.modules.has("REQUEST_PLANNING") &&
									this.props.app.ui.rights.has("PLAN_REQUESTS")) ||
								this.props.app.ui.modules.has("PLAN_RESOURCES")
									? (e) =>
											this.props.calendar.handleResClick(
												e,
												project.id,
												subId === "main" ? false : subId,
												personalId
											)
									: null
							}
						/>
					</DraggableCore>
				);
			}

			const marked = this.state.calendarState.selected.includes(
				project.id + "#" + (subId === "main" ? "*" : subId) + "#*"
			);

			lines.push(
				<tr
					key={"tr" + subId}
					className={subId === "main" ? "project_main" : "project_process"}
				>
					<td
						className="project_head sticky"
						style={{
							cursor: "pointer",
							left: 0,
							zIndex: 3,
							verticalAlign: "top",
						}}
					>
						<div
							className={
								"project_name" +
								(marked ? " marked" : "") +
								(this.props.app.ui.modules.has("LARGER_PROJECTNAMES")
									? " extended"
									: "")
							}
							style={{
								whiteSpace: "nowrap",
								height: lineHeight,
							}}
						>
							{true && subId === "main" ? (
								<div className="buttonInner">
									<Button
										onClick={() => this.toggleProcessVisible()}
										icon={this.state.processesVisible ? "minus" : "plus"}
										color={
											!resMode
												? null
												: this.props.project.resources.length === 0 ||
												  this.props.project.openRequests.length
												? "red"
												: "green"
										}
										size="mini"
										compact
									/>
								</div>
							) : null}
							<div
								className="nameInner"
								title={subprocess.name}
								onClick={
									(this.props.app.ui.modules.has("REQUEST_PLANNING") &&
										this.props.app.ui.rights.has("PLAN_REQUESTS")) ||
									this.props.app.ui.modules.has("PLAN_RESOURCES")
										? (e) =>
												this.props.calendar.handleResClick(
													e,
													project.id,
													subId === "main" ? false : subId,
													false
												)
										: subId === "main"
										? () => this.toggleProcessVisible()
										: null
								}
							>
								{subprocess.name}
							</div>
							{subId === "main" ? (
								<div className="buttonInner">
									{this.props.app.ui.modules.has("AGF") ? (
										<AGFModal size="mini" projectId={project.id} />
									) : null}
									<Button
										onClick={() => project.setFixed(!project.fixed)}
										icon={project.fixed ? "lock" : "lock open"}
										disabled={!project.editable}
										size="mini"
										active={project.fixed}
										compact
									/>
									{this.props.app.ui.rights.has("VIEW_RESOURCES") ||
									this.props.app.ui.rights.has("VIEW_BOARD") ? (
										<ConfirmButton
											onClick={(e) => this.deleteResources(project) }
											question={["questions.deleteResources"]}
											ok="yes"
											cancel="no"
											trigger={
												<Button icon="group" size="mini" compact className="delete-resources-icon-custom" />
											}
										/>
									) : null}
									{project.editable ? (
										<ConfirmButton
											onClick={(e) => project.delete()}
											question={["questions.delete", { name: subprocess.name }]}
											ok="yes"
											cancel="no"
											trigger={
												<Button icon="trash alternate" size="mini" compact />
											}
										/>
									) : null} 

									<Button
										as={Link}
										to={"/project/" + project.id}
										icon={project.editable ? "pencil" : "info"}
										size="mini"
										compact
									/>
								</div>
							) : null}
						</div>
					</td>
					<td> 
						<div
							className={"project_body " + (marked ? "marked" : "") }
							style={{
								zIndex: 2,
								width: width,
								height: lineHeight,
								position: "relative",
							}}
						>
							{boxes}
						</div>
					</td>
				</tr>
			);
		}

		if (
			resMode &&
			this.state.processesVisible &&
			this.props.project.openRequests.length
		)
			lines.push(
				<ProjectRequestLines
					key="projectrequests"
					xwidth={this.props.xwidth}
					pstate={this.state}
					openRequests={this.props.project.openRequests}
					classSelection={Array.from(this.props.app.ui.currentClass)}
					calendar={this.props.calendar}
									/>
			);

		if (resMode && this.state.processesVisible)
			lines.push(
				<ProjectResourceLines
					key="projectresources"
					xwidth={this.props.xwidth}
					pstate={this.state}
					project={this.props.project}
					classSelection={Array.from(this.props.app.ui.currentClass)}
									/>
			);

		return <React.Fragment>{lines}</React.Fragment>;
	}
}

export default inject("app")(observer(ProjectLine));
