import React from "react";
import { useTranslation } from "react-i18next";

const LiteCodes = ({ data }) => {
	const { t } = useTranslation();
	//console.log(data);
	return (
		<React.Fragment>
			{data.codes.map((x, index) => (
				<div className="code" key={x.name+index}>
					<div className="codeName">
						{x.freetext
							? x.name
							: x.addInterface
							? t("edit.lite.codes.interface") + " " + x.name
							: t("edit.lite.codes." + x.name)}
					</div>
					<div className="codeCode">{x.code}</div>
				</div>
			))}
			
		</React.Fragment>
	);
};

export default LiteCodes;
