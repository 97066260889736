import React from "react";
import {
	Modal,
	Button,
	Menu,
	Icon,
	Header,
	Table
} from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ReactToPrint from 'react-to-print';

class AGFModal extends React.Component {
	constructor(props) {
		super(props);
		this.close = this.close.bind(this);
		this.open = this.open.bind(this);
		this.state = {
			open: false,
			data: {},
		};
		this.componentRef = null;
	}

	close() {
		this.setState({ open: false });
	}

	open() {
		this.setState({ open: true });
	}

	render() {
		if (!this.props.app.projects.projects.has(this.props.projectId))
			return null;

		const project = this.props.app.projects.projects.get(this.props.projectId);

		if (!project.agfData || !project.agfData.data) return null;

		const color =
			project.agfData.hash === project.agfData.hashAccepted
				? "green"
				: project.agfData.hashAccepted === "deny"
				? "red"
				: "yellow";

		const data = project.agfData.data;

		return (
			<React.Fragment>
				<Modal
					size="fullscreen"
					centered={false}
					className="agfModal"
					trigger={
						<Button
							size={this.props.size}
							compact
							color={color}
							icon="eye"
							onClick={this.open}
						/>
					}
					open={this.state.open}
					onClose={this.close}
				>
					<Menu>
						<Menu.Item header>{this.props.t("agf.heading")}</Menu.Item>
										
						<ReactToPrint
						documentTitle={project.fullName}
          trigger={() => {
            return <Menu.Item
										name="print"
										
									>
										<Icon name="print" /> Afdrukken
						</Menu.Item>;
          }}
          content={() => this.componentRef}
        />
						
						<Menu.Menu position="right">
							{this.props.app.ui.rights.has("CHANGE_AGF") ? (
								<React.Fragment>
									<Menu.Item
										color="green"
										name="close it"
										onClick={() => {
											project.agfState(true);
											this.close();
										}}
									>
										<Icon name="check" /> Accepteren
									</Menu.Item>
									<Menu.Item
										color="red"
										name="close it"
										onClick={() => {
											project.agfState(false);
											this.close();
										}}
									>
										<Icon name="ban" /> Afwijzen
									</Menu.Item>
								</React.Fragment>
							) : null}
							<Menu.Item name="close it" onClick={this.close}>
								<Icon name="close" /> {this.props.t("cancel")}
							</Menu.Item>
						</Menu.Menu>
					</Menu>

					<Modal.Content>
						{this.state.open ? (
							<div className="agfWrapper">
							<div ref={el => (this.componentRef = el)} >
								<Table celled>
									<Table.Header>
									<Table.Row>
										<Table.HeaderCell colSpan={2}>
											Projectgegevens
										</Table.HeaderCell>
										<Table.HeaderCell colSpan={2}>Versie</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										<Table.Row>
											<Table.Cell className="bold">
												Uitv. Projectnummer
											</Table.Cell>
											<Table.Cell>{project.bstn}</Table.Cell>
											<Table.Cell className="bold">Planperiode</Table.Cell>
											<Table.Cell>{data.datePeriod}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="bold">
												Uitv. Projectomschrijving
											</Table.Cell>
											<Table.Cell>{project.name}</Table.Cell>
											<Table.Cell className="bold">
												Geplande setdagen
											</Table.Cell>
											<Table.Cell>{data.dateDuration}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="bold">Opdrachtgever</Table.Cell>
											<Table.Cell>{data.opdrachtgever}</Table.Cell>
											<Table.Cell className="bold">
												Begrootte setdagen
											</Table.Cell>
											<Table.Cell>{data.dateDuration2}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="bold">Uitvoerder</Table.Cell>
											<Table.Cell>{data.foremanName}</Table.Cell>
											<Table.Cell className="bold">Transportkosten</Table.Cell>
											<Table.Cell>{data.transportCost}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="bold">Tel. Uitvoerder</Table.Cell>
											<Table.Cell>{data.foremanTel}</Table.Cell>
											<Table.Cell className="bold">
												Besteknr. Project
											</Table.Cell>
											<Table.Cell>{data.contractNumber}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="bold">
												Contractvoorwaarden
											</Table.Cell>
											<Table.Cell>{data.contractType}</Table.Cell>
											<Table.Cell className="bold">Keuringsplan</Table.Cell>
											<Table.Cell>{data.keuringsplan}</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
								<Header as="h3">
									Versiebeheer & Autorisatie Projectopdracht
								</Header>
								<Table celled>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>VS</Table.HeaderCell>
											<Table.HeaderCell>Datum</Table.HeaderCell>
											<Table.HeaderCell colSpan={2}>
												Opgesteld door
											</Table.HeaderCell>
											<Table.HeaderCell colSpan={2}>
												{project.agfData.hashAccepted === "deny"
													? "Afgewijzen door"
													: "Geautoriseerd door"}
											</Table.HeaderCell>
										</Table.Row>
										<Table.Row>
											<Table.HeaderCell />
											<Table.HeaderCell />
											<Table.HeaderCell />
											<Table.HeaderCell>d.d.</Table.HeaderCell>
											<Table.HeaderCell />
											<Table.HeaderCell>d.d.</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										<Table.Row
											positive={color === "green"}
											negative={color === "red"}
										>
											<Table.Cell>{project.agfData.id}</Table.Cell>
											<Table.Cell>
												{moment(project.agfData.date).format("DD.MM.YYYY")}
											</Table.Cell>
											<Table.Cell>{project.agfData.name}</Table.Cell>
											<Table.Cell>
												{moment(project.agfData.date).format("DD.MM.YYYY")}
											</Table.Cell>

											<Table.Cell>{project.agfData.nameAccepted}</Table.Cell>
											<Table.Cell>
												{project.agfData.dateAccepted !== "0000-00-00"
													? moment(project.agfData.dateAccepted).format(
															"DD.MM.YYYY"
													  )
													: ""}
											</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
								<Table celled compact size="small">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Middel</Table.HeaderCell>
											<Table.HeaderCell>Molen</Table.HeaderCell>
											<Table.HeaderCell>middel</Table.HeaderCell>
											<Table.HeaderCell>ton</Table.HeaderCell>
											<Table.HeaderCell>oppvl [m²]</Table.HeaderCell>
											<Table.HeaderCell>Dikte [mm]</Table.HeaderCell>
											<Table.HeaderCell>Dichtheid [Ton/m³]</Table.HeaderCell>
											<Table.HeaderCell>Klasse/TT</Table.HeaderCell>
											<Table.HeaderCell>Onderbaan</Table.HeaderCell>
											<Table.HeaderCell>afstr.</Table.HeaderCell>
											<Table.HeaderCell>Best.-post</Table.HeaderCell>
											<Table.HeaderCell>V/NV</Table.HeaderCell>
											<Table.HeaderCell>ton/m²</Table.HeaderCell>
											<Table.HeaderCell>Kbw pst</Table.HeaderCell>
											<Table.HeaderCell>
												Opmerking / Alternatieven
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{data.sections.map((s) => (
											<React.Fragment key={s.name}>
												<Table.Row>
													<Table.Cell />
													<Table.Cell />
													<Table.Cell className="bold" colSpan={5}>
														{s.name}
													</Table.Cell>
													<Table.Cell colSpan={3}>{s.additions}</Table.Cell>
													<Table.Cell colSpan={5}>{s.position}</Table.Cell>
												</Table.Row>
												{s.layers.map((l) => (
													<Table.Row key={s.name + l.mixtureName}>
														<Table.Cell>{l.mixtureCode}</Table.Cell>
														<Table.Cell>{l.mixingPlant}</Table.Cell>
														<Table.Cell>{l.mixtureName}</Table.Cell>
														<Table.Cell>{l.tonnage}</Table.Cell>
														<Table.Cell>{l.area}</Table.Cell>
														<Table.Cell>{l.thickness}</Table.Cell>
														<Table.Cell>{l.density}</Table.Cell>
														<Table.Cell>{l.dura1}</Table.Cell>
														<Table.Cell>{l.dura2}</Table.Cell>
														<Table.Cell>{l.dura3}</Table.Cell>
														<Table.Cell>{l.dura4}</Table.Cell>
														<Table.Cell>{l.dura5}</Table.Cell>
														<Table.Cell>{l.dura6}</Table.Cell>
														<Table.Cell>{l.dura7}</Table.Cell>
														<Table.Cell>{l.dura8}</Table.Cell>
													</Table.Row>
												))}
											</React.Fragment>
										))}
									</Table.Body>
								</Table>
								<Table celled>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell colSpan={2}>Diversen</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										<Table.Row>
											<Table.Cell className="bold">
												Verkeersmaatregelen
											</Table.Cell>
											<Table.Cell>{data.traffic}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="bold">Inzet vervoer</Table.Cell>
											<Table.Cell>{data.transport}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="bold">
												Besteks~/ kortingsbep./bijz.
											</Table.Cell>
											<Table.Cell>{data.specialFeatures}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="bold">
												Diversen / Opmerkingen:
											</Table.Cell>
											<Table.Cell>{data.additions}</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
							</div></div>
						) : null}
					</Modal.Content>
				</Modal>
			</React.Fragment>
		);
	}
}

export default withTranslation()(inject("app")(observer(AGFModal)));
