import React from "react";
import {
	Modal,
	Button,
	Menu,
	Icon,
	Accordion,
	Header,
	Segment,
	Dropdown,
	Form,
	Divider, Label,
	Message,
	Table,
} from "semantic-ui-react";
import { isValidReference } from "mobx-state-tree";
import Color from "color";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import uuid from "uuid";

class QuestionaryEditModal extends React.Component {
	constructor(props) {
		super(props);
		this.close = this.close.bind(this);
		this.open = this.open.bind(this);
		this.save = this.save.bind(this);
		this.state = {
			open: false,
			name: "",
			ordering: 9999,
			activeDomain: "",
			branches: [],
			processes: [],
			classes: [],
		};
	}

	close() {
		this.setState({ open: false, activeDomain: "" });
	}

	open() {
		this.setState({ open: true });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.open && !prevState.open) {
			this.getData();
		}
	}

	getData() {
		console.log("GET DATA");
		if (
			this.props.questionaryId &&
			this.props.app.questions.questionaries.has(this.props.questionaryId)
		) {
			const questionary = this.props.app.questions.questionaries.get(
				this.props.questionaryId
			);
			this.setState({
				name: questionary.name,
				ordering: questionary.ordering,
				branches: Array.from(questionary._groups.values())
					.filter((x) => isValidReference(() => x) && !x.deleted)
					.map((x) => x.id),
				classes: Array.from(questionary._resClasses.values())
					.filter((x) => isValidReference(() => x) && !x.deleted)
					.map((x) => x.id),
				processes: Array.from(questionary._processTemplates.values())
					.filter((x) => isValidReference(() => x) && !x.deleted)
					.map((x) => x.id),
			});
		} else {
			this.setState({
				name: "",
				ordering: Math.round(new Date()/1000),
				branches: [],
				processes: [],
				classes: [],
			});
		}
	}

	remove(d, i) {
		this.setState((s) => {
			const nx = s[d].slice();
			nx.splice(i, 1);
			return { [d]: nx };
		});
	}

	addLine(d) {
		this.setState((s) => {
			const nx = s[d].slice();
			nx.push("");
			return { [d]: nx };
		});
	}

	setDropdown(d, i, v) {
		this.setState((s) => {
			const nx = s[d].slice();
			nx[i] = v;
			return { [d]: nx };
		});
	}

	save() {
		const id = this.props.questionaryId ? this.props.questionaryId : uuid.v4();
		const name = this.state.name.trim();
		const q = {
			id: id,
			name: name.length ? name : this.props.t(
									"questions.questionaryEdit.name.placeholder"
								),
			ordering: this.state.ordering,
			updatedAt: new Date(),
			_groups: this.state.branches.filter(x => x.length),
			_processTemplates: this.state.processes.filter(x => x.length),
			_resClasses: this.state.classes.filter(x => x.length)
		};
		this.props.app.questions.updateQuestionaries([q]);
		this.close();
	}

	render() {
		const options = {
			processes: Array.from(this.props.app.basedata.processTemplates.values())
				.filter((x) => !x.deleted)
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((x) => ({
					text: x.name,
					value: x.id,
					key: x.id,
					icon: "picon-" + x.icon,
					style: {
						backgroundColor: x.color,
						color: new Color(x.color).isLight() ? "black" : "white",
					},
				})),
			branches: Array.from(this.props.app.questions.usergroups.values())
				.filter((x) => !x.deleted)
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((x) => ({
					text: x.name,
					value: x.id,
					key: x.id,
				})),
			classes: Array.from(this.props.app.resources.classes.values())
				.filter((x) => !x.deleted)
				.sort((a, b) =>
					a.human && !b.human
						? -1
						: !a.human && b.human
						? 1
						: a.name.localeCompare(b.name)
				)
				.map((x) => ({
					text: x.name,
					value: x.id,
					key: x.id,
				})),
		};

		return (
			<React.Fragment>
				<Modal
					centered={false}
					className="questionaryModal xModal"
					trigger={React.cloneElement(this.props.trigger, {
						onClick: (e) => this.open(),
					})}
					open={this.state.open}
					onClose={this.close}
				>
					<Menu>
						<Menu.Item header>
							{this.props.questionaryId
								? this.props.t("questions.questionaryEdit.headingEdit")
								: this.props.t("questions.questionaryEdit.headingAdd")}
						</Menu.Item>
						<Menu.Menu position="right">
							<Menu.Item name="save it" onClick={this.save}>
								<Icon name="check" /> {this.props.t("ok")}
							</Menu.Item>
							<Menu.Item name="close it" onClick={this.close}>
								<Icon name="close" /> {this.props.t("cancel")}
							</Menu.Item>
						</Menu.Menu>
					</Menu>

					<Modal.Content>
						<Form size="large">
							<Form.Input
								label={this.props.t("questions.questionaryEdit.name.label")}
								placeholder={this.props.t(
									"questions.questionaryEdit.name.placeholder"
								)}
								type="text"
								value={this.state.name}
								onChange={(e) =>
									this.setState({
										name: e.target.value,
									})
								}
							/>
						</Form>
						<Divider horizontal className="constraintDivider">
							<Header as="h4">
								<Icon name="eye" />
								{this.props.t("questions.questionaryEdit.dividers.title")}
							</Header>
						</Divider>
						<Accordion fluid styled>
							{["branches", "processes", "classes"].map((domain) => (
								<React.Fragment key={domain}>
									<Accordion.Title
										active={this.state.activeDomain === domain}
										index={domain}
										onClick={() => this.setState({activeDomain: this.state.activeDomain === domain ? "" : domain})}
									>
										<div className="acctitl"><Icon name="dropdown" />
										{this.props.t(
											"questions.questionaryEdit.dividers." + domain
										)}</div>
										{this.state[domain].length ? <Label size="small">{this.state[domain].length}</Label> : null }
									</Accordion.Title>
									<Accordion.Content active={this.state.activeDomain === domain}>
										{this.state[domain].length ? (
											<Table attached="top" basic>
												<Table.Body>
													{this.state[domain].map((x, i) => (
														<Table.Row key={uuid.v4()}>
															<Table.Cell>
																<Dropdown
																	fluid
																	selection
																	placeholder={this.props.t("choose.initial")}
																	onChange={(e, data) => {
																		this.setDropdown(domain, i, data.value);
																	}}
																	options={options[domain]}
																	value={x}
																/>
															</Table.Cell>
															<Table.Cell collapsing>
																<Button
																	onClick={() => this.remove(domain, i)}
																	icon="trash alternate"
																/>
															</Table.Cell>
														</Table.Row>
													))}
												</Table.Body>
											</Table>
										) : (
											<Message
												attached
												info
												header={this.props.t(
													"questions.questionaryEdit.messages." +
														domain +
														".heading"
												)}
												content={this.props.t(
													"questions.questionaryEdit.messages." +
														domain +
														".alt",
													{
														button: this.props.t(
															"questions.questionaryEdit.messages." +
																domain +
																".add"
														),
													}
												)}
											/>
										)}
										<Segment attached="bottom" textAlign="right" secondary>
											<Button
												labelPosition="left"
												content={this.props.t(
													"questions.questionaryEdit.messages." +
														domain +
														".add"
												)}
												icon="add"
												onClick={() => this.addLine(domain)}
											/>
										</Segment>
									</Accordion.Content>
								</React.Fragment>
							))}
						</Accordion>
					</Modal.Content>
				</Modal>
			</React.Fragment>
		);
	}
}

export default withTranslation()(inject("app")(observer(QuestionaryEditModal)));
