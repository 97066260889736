import React from "react";
import {
	Icon,
	Segment,
	Form,
	Divider,
	Message,
	Button,
	Checkbox
} from "semantic-ui-react";
import { SortableElement, sortableHandle } from "react-sortable-hoc";
import InfoCircle from "./InfoCircle";
import { useTranslation } from "react-i18next";
import uuid from "uuid";

/*

type: "TEXT","TIME","TIMESPAN","NUMBER","SELECT"
				title: "",
				properties: {},
				focus: "PROJECT",
				_resclass: null,
				visibility: "PUBLIC",
				_supervisors: [],

 */

const getInterfaceTypeLabel = (types, data, t) => {
	const typeId = data.interfaceType;
	const label = t("questions.questEdit.interfaceType.label");
	if (typeId === "NONE")
		return (
			<InfoCircle
				label={label}
				info={t("questions.questEdit.interfaceType.info")}
			/>
		);
	for (let typex of types) {
		if (typex.key !== typeId) continue;
		if (Object.keys(typex.constraints).length === 0) return label;
		const unfilled = [];
		for (let key in typex.constraints) {
			if (typex.constraints[key].includes(data[key])) continue;
			const ist = t("questions.questEdit." + key + "." + data[key]);
			const soll = [];
			for (let sl of typex.constraints[key]) {
				soll.push(t("questions.questEdit." + key + "." + sl));
			}
			let sollString;
			if (soll.length > 2)
				sollString =
					soll.slice(0, -1).join("','") +
					"' " +
					t("questions.questEdit.interfaceType.or") +
					" '" +
					soll.slice(-1);
			else
				sollString = soll.join(
					"' " + t("questions.questEdit.interfaceType.or") + " '"
				);
			unfilled.push(
				t("questions.questEdit.interfaceType.constraintInfo", {
					type: t("questions.questEdit." + key + ".label"),
					current: ist,
					target: sollString,
				})
			);
		}
		if (unfilled.length === 0)
			return (
				<InfoCircle
					label={label}
					info={t("questions.questEdit.interfaceType.info")}
				/>
			);
		unfilled.unshift(t("questions.questEdit.interfaceType.warning"));
		return <InfoCircle label={label} info={unfilled} warning />;
	}

	return (
		<InfoCircle
			label={label}
			info={t("questions.questEdit.interfaceType.info")}
		/>
	);
};

const DragHandle = sortableHandle(({ type }) => (
	<Icon
		name={
			{
				TEXT: "font",
				TIME: "clock",
				TIMESPAN: "stopwatch",
				NUMBER: "hashtag",
				SELECT: "list alternate",
			}[type]
		}
		size="large"
		style={{ cursor: "row-resize", marginRight: 10 }}
	/>
));

export default SortableElement(
	({
		data,
		index2,
		active,
		setActive,
		onChange,
		selectOptionHandler,
		resclasses,
		classConstraints,
	}) => {
		const { t } = useTranslation();

		const typeOptions = [
			{
				text: t("questions.questEdit.type.TEXT"),
				value: "TEXT",
				key: "TEXT",
				icon: "font",
			},
			{
				text: t("questions.questEdit.type.SELECT"),
				value: "SELECT",
				key: "SELECT",
				icon: "list alternate",
			},
			{
				text: t("questions.questEdit.type.TIME"),
				value: "TIME",
				key: "TIME",
				icon: "clock",
			},
			{
				text: t("questions.questEdit.type.TIMESPAN"),
				value: "TIMESPAN",
				key: "TIMESPAN",
				icon: "stopwatch",
			},
			{
				text: t("questions.questEdit.type.NUMBER"),
				value: "NUMBER",
				key: "NUMBER",
				icon: "hashtag",
			},
		];

		const interfaceTypeOptions = [
			{
				text: t("questions.questEdit.interfaceType.NONE"),
				value: "NONE",
				key: "NONE",
				constraints: {},
			},
			{
				text: t("questions.questEdit.interfaceType.WH_WORK"),
				value: "WH_WORK",
				key: "WH_WORK",
				constraints: {
					type: ["TIMESPAN"],
					reference: ["RESOURCE"],
				},
			},
			{
				text: t("questions.questEdit.interfaceType.WH_PAUSE"),
				value: "WH_PAUSE",
				key: "WH_PAUSE",
				constraints: {
					type: ["TIMESPAN"],
					reference: ["RESOURCE"],
				},
			},
			{
				text: t("questions.questEdit.interfaceType.WH_STANDBY"),
				value: "WH_STANDBY",
				key: "WH_STANDBY",
				constraints: {
					type: ["TIMESPAN"],
					reference: ["RESOURCE"],
				},
			},
		];
		return (
			<div>
				<Segment
					onClick={() => setActive(data.id)}
					style={{ cursor: "pointer", userSelect: "none" }}
					attached={index2 === 0 ? "top" : true}
					className="questionHeader"
				>
					<DragHandle type={data.type} />
					<div

						className="questionHeaderLabel"
						style={data.title.length ? null : { color: "#888" }}
					>
						<p style={{overflowWrap:'anywhere'}} >

						{data.title.length ? data.title : t("questions.questEdit.noTitle")}
						</p>
					</div>
					<Button
						size="tiny"
						onClick={() => onChange(index2, "deleted", true)}
						icon="trash alternate"
					/>
				</Segment>
				{active ? (
					<Segment attached>
						<Form>
							<Form.Group>
								<Form.Select
									width={4}
									label={t("questions.questEdit.type.label")}
									value={data.type}
									onChange={(e, data) => onChange(index2, "type", data.value)}
									options={typeOptions}
								/>
								<Form.Input
									width={14}
									label={t("questions.questEdit.question.label")}
									value={data.title}
									onChange={(e) => onChange(index2, "title", e.target.value)}
									placeholder={t("questions.questEdit.question.placeholder")}
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Select
									label={
										<InfoCircle
											label={t("questions.questEdit.reference.label")}
											info={t("questions.questEdit.reference.info", {
												ONCE: t("questions.questEdit.reference.ONCE"),
												RESOURCE: t("questions.questEdit.reference.RESOURCE"),
											})}
										/>
									}
									value={data.reference}
									onChange={(e, data) =>
										onChange(index2, "reference", data.value)
									}
									options={[
										{
											text: t("questions.questEdit.reference.ONCE"),
											value: "ONCE",
											key: "ONCE",
											icon: "road",
										},
										{
											text: t("questions.questEdit.reference.RESOURCE"),
											value: "RESOURCE",
											key: "RESOURCE",
											icon: "picon picon-excavator",
										},
									]}
								/>
								<Form.Select
									label={getInterfaceTypeLabel(interfaceTypeOptions, data, t)}
									value={data.interfaceType}
									onChange={(e, data) =>
										onChange(index2, "interfaceType", data.value)
									}
									options={interfaceTypeOptions}
								/>
							</Form.Group>
							{data.type === "SELECT" ? (
								<Form.Field>
									<label>{t("questions.questEdit.select.options")}</label>
									{data.properties.selectOptions &&
									data.properties.selectOptions.length ? (
										data.properties.selectOptions.map((x, i) => (
											<div className="smallFieldWrapper" key={uuid.v4()}>
												<Form.Input
													size="small"
													value={x}
													onChange={(e) =>
														selectOptionHandler(
															index2,
															"change",
															i,
															e.target.value
														)
													}
													placeholder={t("questions.questEdit.select.option")}
												/>
												<Button
													size="small"
													onClick={() =>
														selectOptionHandler(index2, "remove", i)
													}
													icon="trash alternate"
												/>
											</div>
										))
									) : (
										<Message size="small" className="messageMargin">
											{t("questions.questEdit.select.noOptions")}
										</Message>
									)}
									<div className="smallButtonWrapper">
										<Button
											size="small"
											labelPosition="left"
											content={t("questions.questEdit.select.add")}
											icon="add"
											onClick={() => selectOptionHandler(index2, "add")}
										/>
									</div>
								</Form.Field>
							) : data.type === "TIMESPAN" && false ? (
								<React.Fragment>
									<Form.Group widths="equal">
										<Form.Input
											label={t("questions.questEdit.timespan.startLabel")}
											value={
												data.properties.timespanStart
													? data.properties.timespanStart
													: ""
											}
											onChange={(e) =>
												onChange(
													index2,
													"properties",
													e.target.value,
													"timespanStart"
												)
											}
											placeholder={t(
												"questions.questEdit.timespan.startPlaceholder"
											)}
										/>
										<Form.Input
											label={t("questions.questEdit.timespan.endLabel")}
											value={
												data.properties.timespanEnd
													? data.properties.timespanEnd
													: ""
											}
											onChange={(e) =>
												onChange(
													index2,
													"properties",
													e.target.value,
													"timespanEnd"
												)
											}
											placeholder={t(
												"questions.questEdit.timespan.endPlaceholder"
											)}
										/>
									</Form.Group>
								</React.Fragment>
							) : null}

							{data.reference === "RESOURCE" ? (
								<React.Fragment>
									<Divider />

									<Form.Field>
										<InfoCircle
											label={t("questions.questEdit.resource.label")}
											info={t("questions.questEdit.resource.info")}
										/>

										{data._resclasses.length ? (
											data._resclasses.map((x, i) => (
												<div className="smallFieldWrapper" key={x.id}>
													<Form.Select
														value={x}
														onChange={(e, data) =>
															selectOptionHandler(
																index2,
																"changeResClass",
																i,
																data.value
															)
														}
														error={
															x.length &&
															data.visibility === "PRIVATE" &&
															classConstraints.size > 0 &&
															!classConstraints.has(x)
																? t(
																		"questions.questEdit.resource.questionaryConstraintError"
																  )
																: false
														}
														size="small"
														options={resclasses}
														placeholder={t("choose.initial")}
													/>

													<Button
														size="small"
														onClick={() =>
															selectOptionHandler(index2, "removeResClass", i)
														}
														icon="trash alternate"
													/>
												</div>
											))
										) : (
											<Message size="small" className="messageMargin">
												{t("questions.questEdit.resource.noResources")}
											</Message>
										)}
										<div className="smallButtonWrapper">
											<Button
												size="small"
												labelPosition="left"
												content={t("questions.questEdit.resource.add")}
												icon="add"
												onClick={() =>
													selectOptionHandler(index2, "addResClass")
												}
											/>
										</div>
									</Form.Field>

									<Divider />
									<Form.Group widths="equal">
										<Form.Select
											label={
												<InfoCircle
													label={t("questions.questEdit.focus.label")}
													info={t("questions.questEdit.focus.info", {
														PROJECT: t("questions.questEdit.focus.PROJECT"),
														DAY: t("questions.questEdit.focus.DAY"),
													})}
												/>
											}
											value={data.focus}
											onChange={(e, data) =>
												onChange(index2, "focus", data.value)
											}
											options={[
												{
													text: t("questions.questEdit.focus.PROJECT"),
													value: "PROJECT",
													key: "PROJECT",
													icon: "picon picon-excavator",
												},
												{
													text: t("questions.questEdit.focus.DAY"),
													value: "DAY",
													key: "DAY",
													icon: "calendar alternate outline",
												},
											]}
										/>
										<Form.Select
											label={
												<InfoCircle
													label={t("questions.questEdit.visibility.label")}
													info={t("questions.questEdit.visibility.info", {
														PUBLIC: t("questions.questEdit.visibility.PUBLIC"),
														PRIVATE: t(
															"questions.questEdit.visibility.PRIVATE"
														),
													})}
												/>
											}
											value={data.visibility}
											onChange={(e, data) =>
												onChange(index2, "visibility", data.value)
											}
											options={[
												{
													text: t("questions.questEdit.visibility.PUBLIC"),
													value: "PUBLIC",
													key: "PUBLIC",
													icon: "eye",
												},
												{
													text: t("questions.questEdit.visibility.PRIVATE"),
													value: "PRIVATE",
													key: "PRIVATE",
													icon: "privacy",
												},
											]}
										/>
									</Form.Group>
									{data.visibility === "PRIVATE" ? (
										<Form.Field>
											<InfoCircle
												label={t("questions.questEdit.supervisors.label")}
												info={t("questions.questEdit.supervisors.info")}
											/>

											{data._supervisors.length ? (
												data._supervisors.map((x, i) => (
													<div className="smallFieldWrapper" key={x.id}>
														<Form.Select
															value={x}
															onChange={(e, data) =>
																selectOptionHandler(
																	index2,
																	"changeSupervisor",
																	i,
																	data.value
																)
															}
															error={
																false &&
																x.length &&
																classConstraints.size > 0 &&
																!classConstraints.has(x)
																	? t(
																			"questions.questEdit.resource.questionaryConstraintError"
																	  )
																	: false
															}
															size="small"
															options={resclasses}
															placeholder={t("choose.initial")}
														/>

														<Button
															size="small"
															onClick={() =>
																selectOptionHandler(
																	index2,
																	"removeSupervisor",
																	i
																)
															}
															icon="trash alternate"
														/>
													</div>
												))
											) : (
												<Message size="small" className="messageMargin">
													{t("questions.questEdit.supervisors.noSupervisors")}
												</Message>
											)}
											<div className="smallButtonWrapper">
												<Button
													size="small"
													labelPosition="left"
													content={t("questions.questEdit.supervisors.add")}
													icon="add"
													onClick={() =>
														selectOptionHandler(index2, "addSupervisor")
													}
												/>
											</div>
										</Form.Field>
									) : null}
								</React.Fragment>
							) : null}
							{/* Signature checkbox */}
							<br />
							<Checkbox label={t("addSignatureFieldLabel")} 
								className="signature-checkbox"
								checked={data.properties.signatureEnabled}
								onChange={(e, data) => {
										onChange(
											index2,
											"properties",
											data.checked,
											"signatureEnabled"
										)
									}
								}
							/>
							<br />
						</Form>
					</Segment>
				) : null}
			</div>
		);
	}
);
