import React from "react";
import moment from "moment";
import {Button} from "semantic-ui-react";

class Bauzeitenplan extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
			trigger: false
		};
        this.form = React.createRef();
	}

	componentDidUpdate(prevP,prevState){
		if (!prevState.trigger && this.state.trigger){
			this.form.current.submit();
			this.setState({trigger: false});
		}
	}

	open() {
		const data = {
			name: this.props.editState.data.name,
			days: {},
			processes: {}
		};
		for (let pid in this.props.editState.data.processes){
			const process = this.props.editState.data.processes[pid];
			if (process.deleted) continue;
			data.processes[pid] = process.name;
			for (let jid in process.jobs){
				const job = process.jobs[jid];
				if (job.deleted) continue;
				const date = moment(job.start).format("YYYY-MM-DD");
				if (!(date in data.days)) data.days[date] = [];
				data.days[date].push([pid,job.comment]);
			}
		}
		this.setState({
			data: JSON.stringify(data),
			trigger: true
		})
	}

	render() {
		return (
						<React.Fragment><form
						ref={this.form}
							method="post"
							action={""+process.env.REACT_APP_BPO_URL+"/ressourcen/InfraBauzeitenplan.php"}
							target="_blank"
						>
							<input type="hidden" name="mb_data" value={this.state.data} />
						</form>
						<Button icon="file excel outline" onClick={(e) => this.open()} /></React.Fragment>
		);
	}
}

export default Bauzeitenplan;
