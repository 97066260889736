import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import DraggableCore from "react-draggable";
import { Button, Icon } from "semantic-ui-react";
import RequestComment from "../controls/requestComment";
import ProcessTypeChooser from "../controls/processTypeChooser";
import ConfirmButton from "../controls/confirmButton";
import BlurChangeInput from "../controls/blurChangeInput";
import EditLite from "../projecteditview/lite";

class ProjectEditLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dayMap: new Map(),
			pid: "",
			sub: "",
			currentView: props.app.ui.view,
			x: 0,
            editState: props.edit.state,
            unifiedData: props.edit.getUnified()
		};
	}

    static getDerivedStateFromProps(props, state) {
        if (props.edit.state !== state.editState) {
        //   this.setState({
        //     editState: props.edit.state
        //     });
            return {
                editState: props.edit.state,
                unifiedData: props.edit.getUnified()
                };
        }
        return null;
      }

	onDrag(x) {
		let newMap = ProjectEditLine.moveHandler(this.props, this.state, x);

		this.setState({
			x: x,
			dayMap: newMap,
		});
	}

	static getX(props, date) {
		return (
			((date.getTime() - props.app.ui.view.start.getTime()) /
				props.app.ui.calDuration) *
			props.xwidth
		);
	}

	static getDate(props, x) {
		return (props.app.ui.calDuration * x) / props.xwidth;
	}

	static getWidth(props, x) {
		return (x * props.xwidth) / props.app.ui.calDuration;
	}

	getDateFromEvent(e) {
		const x = moment(
			this.props.app.ui.view.start.getTime() +
				ProjectEditLine.getDate(this.props, e.nativeEvent.offsetX)
		).format("YYYY-MM-DD");
		console.log(x);
		return x;
	}

	onStart(sub, pid, start) {
		let alsoMoving = this.props.edit.getDays();
		this.props.edit.setState({ marked: new Set() });
		this.setState({
			dayMap: alsoMoving,
			currentView: this.props.app.ui.view,
			pid: pid,
			sub: sub,
			x: 0,
		});
	}

	static moveHandler(props, state, x) {
		let start = props.edit
			.getUnifiedByDay()
			.get("main")
			.data.get(state.pid);
		const pid = state.pid;
		//console.log(props.project.unifiedByDayWithProcesses, state.sub);
		let daylist = Array.from(
			props.edit.getUnifiedByDayWithProcesses().get(state.sub).data
		).filter(function([key, d]) {
			return (x < 0 && key < pid) || (x > 0 && key > pid);
		});
		daylist.sort(function(a, b) {
			return a[0] < b[0]
				? x > 0
					? -1
					: 1
				: a[0] === b[0]
				? 0
				: x > 0
				? 1
				: -1;
		});

		let xmap = new Map();

		xmap.set(state.pid, x);

		let last = ProjectEditLine.getX(props, start[x < 0 ? "start" : "end"]) + x;

		let used_replacement_days = new Map();

		for (let i = 0; i < daylist.length; i++) {
			let [key, date] = daylist[i];
			let end = ProjectEditLine.getX(props, date[x < 0 ? "end" : "start"]);

			if ((x < 0 && end <= last) || (x > 0 && end >= last)) break;

			let localx = last - end;

			let target_day = moment(
				Math.round((date.start.getTime() + date.end.getTime()) / 2) +
					ProjectEditLine.getDate(props, localx)
			).startOf("day");
			let target_day_x = moment(
				date.start.getTime() + ProjectEditLine.getDate(props, localx)
			).valueOf();
			let weekender = false;

			while (
				!state.dayMap.has(target_day.format("YYYY-MM-DD")) &&
				(!props.app.basedata.checkWorkingDay(target_day).state ||
					used_replacement_days.has(target_day.format("YYYY-MM-DD")))
			) {
				weekender = true;
				target_day.add(x > 0 ? 1 : -1, "day");
			}

			let target_day_y = target_day.valueOf();

			if (weekender) {
				used_replacement_days.set(target_day.format("YYYY-MM-DD"), true);
				localx += ProjectEditLine.getWidth(props, target_day_y - target_day_x);
			}

			xmap.set(key, localx);

			last =
				ProjectEditLine.getX(props, date[x < 0 ? "start" : "end"]) + localx;
		}

		let newMap = new Map();
		for (let [key] of state.dayMap) {
			newMap.set(key, xmap.has(key) ? xmap.get(key) : 0);
		}
		return newMap;
	}

	onStop(start, end, x) {
		let newMap = ProjectEditLine.moveHandler(this.props, this.state, x);
		let processIdToMove = this.state.sub;
		this.setState({
			dayMap: new Map(),
			pid: "",
			sub: "",
			x: 0,
		});

		const moveList = [];
		for (let [processId, process] of Object.entries(
			this.props.edit.state.data.processes
		)) {
			if (processId !== processIdToMove && processIdToMove !== "main") continue;
			for (let [jobId, job] of Object.entries(process.jobs)) {
				let momday = moment(job.start);
				let key = momday.format("YYYY-MM-DD");

				if (!newMap.has(key)) continue;

				let offset = newMap.get(key);

				if (offset === 0) continue;

				let diff = ProjectEditLine.getDate(this.props, offset);

				let olddaystart = moment(momday)
					.startOf("day")
					.valueOf();
				let olddayend = moment(momday)
					.endOf("day")
					.valueOf();

				let newdaystart = olddaystart + diff;
				let newdayend = olddayend + diff;

				let realnewdaystart = moment(
					Math.round((newdaystart + newdayend) / 2)
				).startOf("day");

				let newstart = moment(momday).add(
					realnewdaystart.diff(olddaystart, "day"),
					"day"
				);
				let movediff = newstart.valueOf() - momday.valueOf();

				if (!movediff) continue;

				moveList.push([processId, jobId, movediff]);
			}
		}
		/* eslint-enable no-unused-vars */
		if (moveList.length) {
			this.props.edit.move(moveList);
		}
	}

	render() {
		const lines = [];
		const movemode = !this.state.editState.multiMode;
		const width =
			((this.props.app.ui.view.end - this.props.app.ui.view.start) /
				this.props.app.ui.calDuration) *
			this.props.xwidth;

		const unifiedProjectData = this.state.unifiedData ?? [];

		for (let [subId, subprocess] of unifiedProjectData) {
			const boxes = [];

			const lineHeight = subId === "main" ? 45 : 35;
			const padding = 0;
			const boxHeight = lineHeight - 2 * padding;

			for (let [personalId, dateData] of subprocess.data) {
				let start = ProjectEditLine.getX(this.props, dateData.start);
				let end = ProjectEditLine.getX(this.props, dateData.end);

				let isBeingMoved = this.state.dayMap.has(personalId);

				let pos = 0;
				let offset = 0;

				if (this.state.pid === "") {
				} else if (
					isBeingMoved &&
					personalId !== this.state.pid &&
					(subId === this.state.sub || this.state.sub === "main")
				) {
					offset = this.state.dayMap.get(personalId);
				} else if (
					isBeingMoved &&
					personalId === this.state.pid &&
					(subId === this.state.sub || this.state.sub === "main")
				) {
					pos = this.state.dayMap.get(personalId);
				}

				const starter = function(startx, subid, pid) {
					return function(x, y) {
						return this.onStart(subid, pid, startx);
					}.bind(this);
				}.bind(this)(dateData.start, subId, personalId);

				const ender = function(startx, endx) {
					return function(x, y) {
						return this.onStop(startx, endx, y.x);
					}.bind(this);
				}.bind(this)(dateData.start, dateData.end);
				boxes.push(
					<DraggableCore
						axis="none"
						onDrag={(x, y) => this.onDrag(y.x)}
						onStart={starter}
						key={subId + "-" + personalId}
						onStop={ender}
						//defaultPosition={{ x: pos, y: 0 }}
						position={{ x: pos, y: 0 }}
						disabled={!movemode || this.state.editState.data.fixed}
					>
						<div
							className={
								"ebox standard_box" +
								(this.state.editState.marked.has(subId + "#" + personalId)
									? " editmarked"
									: "") +
								(offset !== 0 || pos !== 0 ? " moved" : "") +
								(this.state.editState.copyOrigins.has(subId + "#" + personalId)
									? " copyOrigin"
									: "")
							}
							style={{
								cursor: movemode ? "pointer" : "default",
								position: "absolute",
								left: start + 1 + offset,
								width: end - start - 1,
								height: boxHeight - 1,
								top: padding,
							}}
							onClick={
								subId === "main"
									? null
									: this.state.editState.deleteMode
									? (e) => {
											e.stopPropagation();
											this.props.edit.removeJob(personalId, subId);
									  }
									: (e) => {
											e.persist();
											e.stopPropagation();
											this.props.edit.handleDayClick(personalId, subId, e);
									  }
							}
						>
							{subId !== "main" ? (
								dateData.bpo.length ? (
									<Icon name="stopwatch" />
								) : (
									<div className="">{dateData.group}</div>
								)
							) : (
								""
							)}
						</div>
					</DraggableCore>
				);
			}

			lines.push(
				<tr
					key={"tr" + subId}
					className={
						subId === "main"
							? "editline project_main"
							: "editline project_process"
					}
				>
					<td
						className="project_head sticky"
						style={{
							left: 0,
							zIndex: 3,
							verticalAlign: "top",
						}}
					>
						<div
							className="project_name"
							style={{
								whiteSpace: "nowrap",
								height: lineHeight,
							}}
						>
							{subId === "main" ? (
								<div className="pedit_pname">
									{subprocess.name.length
										? subprocess.name
										: this.props.t("project.unnamed")}
								</div>
							) : (
								<React.Fragment>
									<ProcessTypeChooser
										value={subprocess.template}
										disabled={!this.state.editState.data.editable}
										onSelect={(e) =>
											this.props.edit.changeProperty(subId, {
												name: e.name,
												template: e.id,
											})
										}
									/>
									{subId !== "main" &&
									!this.state.editState.deleteMode &&
									this.props.app.ui.modules.has("PROCESS_BSTN") ? (
										<RequestComment
											onSave={(value) =>
												this.props.edit.changeProperty(subId, {
													bstn: value,
												})
											}
											text={subprocess.bstn}
											trigger={
												<Button
													size="mini"
													active={subprocess.bstn !== ""}
													className="jnumberbutton"
													compact
												>
													J
												</Button>
											}
										/>
									) : null}
									<BlurChangeInput
										size="mini"
										autoComplete="anyrandomstring"
										placeholder={this.props.t("project.process.name")}
										onBlur={(_) => this.props.edit.saveHistory()}
										onChange={(e) =>
											this.props.edit.changeProperty(subId, {
												name: e.target.value,
											})
										}
										disabled={!this.state.editState.data.editable}
										value={subprocess.name}
									/>
										<ConfirmButton
											question={["questions.delete", { name: subprocess.name }]}
											ok="yes"
											cancel="no"
											trigger={
												<Button icon="trash alternate" size="mini" compact />
											}
											onClick={(e) =>
												this.props.edit.changeProperty(subId, { deleted: true })
											}
										/>
								</React.Fragment>
							)}

							{subId === "main" ? (
								!this.state.editState.deleteMode ? (
									<Button
										icon={
											this.state.editState.data.editable ? "pencil" : "info"
										}
										size="tiny"
										compact
										active={this.state.editState.global}
										onClick={(_) =>
											this.props.edit.setGlobal(!this.state.editState.global)
										}
									/>
								) : null
							) : !this.state.editState.deleteMode &&
							  this.props.app.ui.modules.has("BPO_LITE") &&
							  this.props.app.ui.rights.has("BPO_LITE") ? (
								<EditLite edit={this.props.edit} process={subId} />
							) : null}
						</div>
					</td>
					<td>
						<div
							className="project_body"
							style={{
								cursor:
									subId === "main"
										? "not-allowed"
										: this.state.editState.copyOrigins.size
										? "copy"
										: "pointer",
								zIndex: 2,
								width: width,
								height: lineHeight,
								position: "relative",
							}}
							onClick={
								subId === "main" || !this.state.editState.data.editable
									? null
									: this.state.editState.copyOrigins.size
									? (e) => {
											e.persist();
											this.props.edit.handleDayClick(
												this.getDateFromEvent(e),
												subId,
												e
											);
									  }
									: (e) => {
											e.persist();
											this.props.edit.addJob(
												this.getDateFromEvent(e),
												subId,
												e
											);
									  }
							}
						>
							{boxes}
						</div>
					</td>
				</tr>
			);
		}

		return <React.Fragment>{lines}</React.Fragment>;
	}
}

export default withTranslation()(inject("app")(observer(ProjectEditLine)));
